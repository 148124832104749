export const ORDER_COLUMN_NAME = {
    ORDER_NUMBER:"Order Number",
    ORDER_DATE:"Order Date",
    START_TIME : "Start Time",
    START_END_TIME : "Start Time - End Time",
    END_TIME :"End Time",
    SUM : "Distributed Hydrogen/Ordered Hydrogen",
    SUMP1: "Total Direct Production Volume",
    SUMP2 : "Total Storage Volume",
    SUMP1PRICE: "Total Direct Production Price",
    SUMP2PRICE: "Total Storage Price"
}
export const SUPPLY_LINE_COLUMN_NAME = {
    SUPPLY_ID:"Supply ID",
    DATE_TIME : "Date Time",
    MASS_DELIVERED : "Mass Delivered",
    P1_TOTAL: "Direct Production Volume",
    P2_TOTAL : "Storage Volume",
    P1_PRICE: "Direct Production Price",
    P2_PRICE: "Storage Price"
}
export const HEADER_NAME = "Order Details";
export const customerName = "Company Name :";
export const orderId = "Order Number: ";
export const PO_Number = "Purchase Order Number :";
export const createdAt = "createdAt";
export const startandendtime = "startandendtime";
export const sum = "sum";
export const sumP1Total = "sumP1Total";
export const sumP2Total = "sumP2Total";
export const sumP1Price = "sumP1Price";
export const sumP2Price = "sumP2Price";

export const supplyId = "supplyId";
export const dateTime = "dateTime";
export const massDelivered = "massDelivered";
export const P1Total = "P1Total";
export const P2Total = "P2Total";
export const P1Price = "P1Price";
export const P2Price = "P2Price";