export const CONFIRM_DIALOG_PROVIDER = {
    HEADER:"Leave Page",
    MESSAGE_CONTENT:"You have unsaved changes. Are you sure you want to leave?",
    BTN_CANCEL:"Cancel",
    BTN_OK :"Ok"
}
export const  ERROR_PAGE = {
    EDIT_MESSAGE :"Edit Not Available",
    OK_TEXT :"OK",
    BTN_CLOSE_TXT : "Close",
    BTN_TRY_AGAIN_TXT :"Try again",
}
export const DATE = "date";
export const LOADING_TEXT = "Please wait...";