import React from "react";
import warningIcon from "../../assets/Icons/Subtract.svg";
import { DEMAND_UNIT_OPTIONS_VALUE } from "../../pages/Order/constants";
import styles from "./inputgroup.module.css";
import PropTypes from 'prop-types';
const InputGroup = ({
  value = "",
  extraClass = "",
  inputProps = "",
  errorMsg = "",
  rightLabel = DEMAND_UNIT_OPTIONS_VALUE.UNIT_PER_HOUR,
  showWarningDemand = false,
  inputHint = "",
  mainWrapperClass = "",
  extraWrapperClass = ""
}) => {
  return (
    <div
      className={`${
        inputProps?.disabled
          ? styles.input_disabled
          : `${styles.inputGroup___MainWrapper} ${mainWrapperClass}`
      }`}
    >
      <div
        className={`${styles.inputGroup___wrapper} ${
          errorMsg ? `${styles.inputGroup___wrapper_withError}` : ``
        } ${extraWrapperClass}`}
      >
        {value && (
          <small
            className={`${styles.inputGroup__placeHolder} ${
              errorMsg ? `${styles.inputGroup__placeHolder_withError}` : ``
            }`}
          >
            {inputProps?.placeholder || "Flow"}
          </small>
        )}
        <input
          className={`${styles.inputGroup__Input} ${extraClass}`}
          placeholder=" "
          value={value}
          type="text"
          data-testid="amtIp"
          {...inputProps}
        />
        {inputProps.id === "purchaseOrderNumber" && (
          <label htmlFor="purchaseOrderNumber">
            <span className={styles.first_letter}>Purchase Order Number </span>
            <span className={styles.second_letter}>(optional)</span>
          </label>
        )}
        {rightLabel && (
          <small
            className={`${styles.inputGroup__Unit_label}  ${
              showWarningDemand
                ? `${styles.inputGroup__Unit_label_withWarning}`
                : ``
            }`}
          >
            {rightLabel}
          </small>
        )}
        {showWarningDemand && (
          <img
            src={warningIcon}
            alt="warningIcon"
            className={styles.inputGroup__warningIcon}
          />
        )}
      </div>

      {inputHint && (
        <div className={styles.input__Hint}>
          <p>{inputHint}</p>
        </div>
      )}
      {errorMsg && (
        <small className={styles.inputGroup__Error}>{errorMsg}</small>
      )}
    </div>
  );
};
InputGroup.propTypes = {
  value: PropTypes.any,
  extraClass: PropTypes.string,
  inputProps: PropTypes.any,
  errorMsg: PropTypes.string,
  rightLabel: PropTypes.string,
  showWarningDemand : PropTypes.bool,
  inputHint: PropTypes.string,
  mainWrapperClass: PropTypes.string,
  extraWrapperClass: PropTypes.string,
};
export default InputGroup;
