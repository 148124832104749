import React from "react";
import styles from "./loading.module.css";
import { LOADING_TEXT } from "../ComponentsConstants";
import PropTypes from 'prop-types';

const Loading = ({ loadingText = LOADING_TEXT }) => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loading}>
        <div className={styles.loader}></div>
        <p>{loadingText}</p>
      </div>
    </div>
  );
};
Loading.propTypes = {
  loadingText : PropTypes.string,
};

export default Loading;
