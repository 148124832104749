import { ConfirmDialogProvider } from "../../components/ConfirmDialogProvider/ConfirmDialogProvider";
import { ConfirmContext } from "../../context/confirmContext";
import LandingPage from "./LandingPage";

const MainLayout = () => {
  return (
      <ConfirmDialogProvider>
        <ConfirmContext>
        <LandingPage/>
        </ConfirmContext>
      </ConfirmDialogProvider>
  );
};

export default MainLayout;
