import * as yup from "yup";
import {
  convertDateStrToDateObj,
  dateIsAfter,
  convertDateFromUTCToCET,
} from "../utils/date-utils";

yup.addMethod(yup.string, "pastDate", function (errorMessage) {
  return this.test(`past-date`, errorMessage, function (value) {
    const { path, createError } = this;

    return dateIsAfter(
      convertDateStrToDateObj(convertDateFromUTCToCET(new Date(), "YYYY-MM-DD")),
      convertDateStrToDateObj(value)
    )
      ? createError({ path, message: errorMessage })
      : {};
  });
});

export const emptySchema = yup.object().shape({});

export default yup;
