import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserContext } from "../AuthorizedPage/UserProvider";
import style from "./LeftMenu.module.css";
import hydrogenIcon from "../../assets/Icons/hydrogen2.svg";
import orderHistory from "../../assets/Icons/orderHistory.svg";
import profileIcon from "../../assets/Icons/Profile.svg";
import logOut from "../../assets/Icons/Logout.svg";
import cog from "../../assets/Icons/images.svg";
import StormIcon from '@mui/icons-material/Storm';
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import {
  CREATE_DEMAND,
  VIEW_DEMANDS,
  VIEW_PRODUCTION_PLANS,
  VIEW_SYSTEM_PROPERTY,
  VIEW_CURRENT_PRODUCTION_PLAN,
  UPDATE_SYSTEM_PROPERTY,
  VIEW_ALL_ORDERS_AND_DETAILS,
  ADD_STATIC_COSTS,
  UPDATE_STATIC_COSTS,
  ADD_USER,
  PLACE_ORDER,
  UPDATE_USER_IN_HYOP,
  ADD_USER_TO_HYOP,
  DELETE_USER_FROM_HYOP,
  VIEW_ALL_INVOICES,
  ONBOARD_COMPANY,
  UPDATE_COMPANY_DETAILS,
  DELETE_COMPANY,
  APPROVE_DEMAND,
  REJECT_DEMAND,
  GENERATE_NEW_PRODUCTION
} from "../../store/constants";
import { getOrderPath, getUserCompanies, hasPermission } from "../../utils/utils";
import {
  ORDER_PAGE,
  STATIC_COST_PAGE,
  PROFILE_PAGE,
  USERS_PAGE,
  CONFIRMATION_PAGE,
  SETTINGS_PAGE,
  PRODUCTION_PLAN_PAGE,
  INVOICE_PAGE,
  MENU_NAME,
  COMPANY,
  APPROVAL_LIST,
  ORDER_TYPE,
  FORECAST,
  OVERVIEW_PAGE
} from "../../utils/RoutesConstants";
import tagLine from "../../assets/Icons/tags-line-icon.svg";
import BusinessIcon from "@mui/icons-material/Business";
import InvoiceIcon from "@mui/icons-material/Payment";
import SummarizeIcon from '@mui/icons-material/Summarize';
import { List, ListItem } from "@mui/material";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ConfirmationModal from "../../components/confirmation-modal/ConfirmationModal";
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from "react-i18next";
import { TRANS_KEYS } from "../../utils/Constants";

const LeftMenu = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { signOut } = useContext(UserContext);
  const {t} = useTranslation(TRANS_KEYS.translation, {keyPrefix: TRANS_KEYS.LEFT_MENU});
  const userRolePermission = useSelector(
    (state) => state.orderReducer.userDetails?.permissions
  );
  const userDetails = useSelector((state) => state.orderReducer.userDetails);
  const allowedOrderTypes = getUserCompanies(userDetails?.companies);

  const handleClose = () => {
    setOpen(!open);
  }
  return (
    <>
      <List>
        <ListItem
          key="1"
          disablePadding
          sx={{ display: "block", color: "var(--white)" }}
        >
          <div className={style.buttonHome}>
            {hasPermission(userRolePermission, [CREATE_DEMAND, PLACE_ORDER]) && (
              <Link
                to={getOrderPath(userDetails)}
                className={
                  location.pathname === ORDER_PAGE.ORDER ||
                    location.pathname === ORDER_PAGE.ORDER_TYPE ||
                    location.pathname === ORDER_PAGE.ORDER_TYPE_FLEXIBLE ||
                    location.pathname === ORDER_PAGE.ORDER_TYPE_INDUSTRY ||
                    location.pathname === ORDER_PAGE.ORDER_TYPE_FLEXIBLE_SUMMARY ||
                    location.pathname === ORDER_PAGE.ORDER_FLEXIBLE_DETAILS ||
                    location.pathname === ORDER_PAGE.ORDER_INDUSTRIAL_DETAILS ||
                    location.pathname === ORDER_PAGE.ORDER_HISTORY_FLEXIBLE ||
                    location.pathname === ORDER_PAGE.ORDER_HISTORY_INDUSTRY ||
                    location.pathname === CONFIRMATION_PAGE
                    ? style.firstBtn
                    : style.btn
                }
                data-testid="orderHydrogen"
              >
                <img src={hydrogenIcon} alt="history" />
                {MENU_NAME.ORDER_HYDROGEN}
              </Link>
            )}
            {hasPermission(userRolePermission, [
              VIEW_DEMANDS,
              VIEW_ALL_ORDERS_AND_DETAILS
            ]) && (
                <Link
                  to={ORDER_PAGE.ORDER_HISTORY}
                  className={
                    location.pathname === ORDER_PAGE.ORDER_HISTORY
                      ? style.firstBtn
                      : style.btn
                  }
                >
                  <img src={orderHistory} alt="history" />
                  {MENU_NAME.ORDER_HISTORY}
                </Link>
              )}
            {hasPermission(userRolePermission, [
              APPROVE_DEMAND,
              REJECT_DEMAND
            ]) && (
                <Link
                  to={APPROVAL_LIST}
                  className={
                    location.pathname === APPROVAL_LIST
                      ? style.firstBtn
                      : style.btn
                  }
                >
                  <FactCheckIcon className={style.icon_color} />
                  {MENU_NAME.ORDER_APPROVAL}
                </Link>
              )}

            {hasPermission(userRolePermission, [VIEW_ALL_INVOICES]) && (
              <Link
                to={INVOICE_PAGE}
                className={
                  location.pathname === INVOICE_PAGE ? style.firstBtn : style.btn
                }
              >
                <InvoiceIcon className={style.icon_color} />
                {MENU_NAME.INVOICES}
              </Link>
            )}

            {hasPermission(userRolePermission, [
              ADD_STATIC_COSTS,
              UPDATE_STATIC_COSTS
            ]) && (
                <Link
                  to={STATIC_COST_PAGE}
                  className={
                    location.pathname === STATIC_COST_PAGE
                      ? style.firstBtn
                      : style.btn
                  }
                >
                  <img src={tagLine} alt="tagLine" style={{ marginTop: 7 }} />
                  {MENU_NAME.STATIC_COST}
                </Link>
              )}

            {(allowedOrderTypes?.includes(ORDER_TYPE.INDUSTRY_OFFTAKE) &&
              allowedOrderTypes?.includes(ORDER_TYPE.TRAILER_BAY) && CREATE_DEMAND) && (
                <Link
                  to={FORECAST}
                  className={
                    location.pathname === FORECAST
                      ? style.firstBtn
                      : style.btn
                  }
                  data-testid="forecast"
                >
                  <StormIcon className={style.icon_color} />
                  {MENU_NAME.DA_FORECAST}
                </Link>
              )}

            {hasPermission(userRolePermission, [
              VIEW_PRODUCTION_PLANS,
              VIEW_CURRENT_PRODUCTION_PLAN,
              VIEW_SYSTEM_PROPERTY,
              UPDATE_SYSTEM_PROPERTY,
              GENERATE_NEW_PRODUCTION
            ]) && (
                <Link
                  to={OVERVIEW_PAGE}
                  className={
                    location.pathname === OVERVIEW_PAGE
                      ? style.firstBtn
                      : style.btn
                  }
                  data-testid="overviewBtn"
                >
                   <SummarizeIcon className={style.icon_color} />
                  {MENU_NAME.OVERVIEW}
                </Link>
              )}
              {hasPermission(userRolePermission, [
              VIEW_PRODUCTION_PLANS,
              VIEW_CURRENT_PRODUCTION_PLAN
            ]) && (
                <Link
                  to={PRODUCTION_PLAN_PAGE}
                  className={
                    location.pathname === PRODUCTION_PLAN_PAGE
                      ? style.firstBtn
                      : style.btn
                  }
                  data-testid="productionPlanBtn"
                >
                  <img src={orderHistory} alt="production plan" />
                  {MENU_NAME.PRODUCTION_PLAN}
                </Link>
              )}
            {hasPermission(userRolePermission, [
              ADD_USER,
              ADD_USER_TO_HYOP,
              UPDATE_USER_IN_HYOP,
              DELETE_USER_FROM_HYOP
            ]) && (
                <Link
                  to={USERS_PAGE}
                  className={
                    location.pathname === USERS_PAGE ? style.firstBtn : style.btn
                  }
                >
                  <PeopleOutlineOutlinedIcon className={style.icon_color} />
                  {MENU_NAME.MANAGE_USER}
                </Link>
              )}
            {hasPermission(userRolePermission, [
              ONBOARD_COMPANY,
              UPDATE_COMPANY_DETAILS,
              DELETE_COMPANY
            ]) && (
                <Link
                  to={COMPANY}
                  className={
                    location.pathname === COMPANY ? style.firstBtn : style.btn
                  }
                >
                  <BusinessIcon className={style.icon_color} />
                  {MENU_NAME.MANAGE_COMPANIES}
                </Link>
              )}
            <Link
              to={PROFILE_PAGE}
              className={
                location.pathname === PROFILE_PAGE ? style.firstBtn : style.btn
              }
            >
              <img src={profileIcon} alt="profileIcon" />
              {MENU_NAME.PROFILE}
            </Link>
            {hasPermission(userRolePermission, [
              VIEW_SYSTEM_PROPERTY,
              UPDATE_SYSTEM_PROPERTY
            ]) && (
                <Link
                  to={SETTINGS_PAGE}
                  className={
                    location.pathname === SETTINGS_PAGE ? style.firstBtn : style.btn
                  }
                  data-testid="settingBtn"
                >
                  <img src={cog} alt="settings" />
                  {MENU_NAME.SETTINGS}
                </Link>
              )}
            <Link
              className={style.buttonLogout}
              onClick={() => setOpen(true)}
              data-testid="logout"
            >
              <img src={logOut} alt="logout" />
              {MENU_NAME.LOGOUT}
            </Link>
          </div>
        </ListItem>
      </List>
      <ConfirmationModal
        heading={t(`HEADER`)}
        open={open}
        handleClose={handleClose}
        textToShow={t(`MESSAGE`)}
        handleAction={signOut}
        firstBtnText={t(`BUTTON_CANCEL`)}
        secondBtnText={t(`BUTTON_OK`)}
        actionButtonIcon={<LogoutIcon className={style.icon_color} fontSize="small"/>}
      />
    </>
  );
};

export default LeftMenu;
