import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { orderReducer } from "./reducers/orderReducer";
import { loadingReducer } from "./reducers/loadingReducer";
import { approvalReducer } from "./reducers/approvalReducer";

const reducers = {
  orderReducer: orderReducer,
  loadingReducer: loadingReducer,
  approvalReducer: approvalReducer

};

const reducer = combineReducers(reducers);
const middleware = [thunk];
export const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
