export const ORDER_PAGE = {
  ORDER: "/order",
  ORDER_HISTORY_ORDER_DETAILS: "/orderhistory/order/orderdetails",
  ORDER_HISTORY_INDUSTRY: "/orderhistory/order",
  ORDER_HISTORY: "/orderhistory",
  ORDER_DETAILS: "/order/orderdetails",
  ORDER_TYPE: "/ordertypes",
  ORDER_TYPE_INDUSTRY: "/ordertypes/order",
  ORDER_TYPE_ORDER_DETAILS: "/ordertypes/order/orderdetails",
  ORDER_TYPE_FLEXIBLE_SUMMARY:
    "/ordertypes/flexibledemand/flexibledemandsummary",
  ORDER_HISTORY_FLEXIBLE_SUMMARY:
    "/orderhistory/flexibledemand/flexibledemandsummary",
  ORDER_TYPE_FLEXIBLE: "/ordertypes/flexibledemand",
  ORDER_HISTORY_FLEXIBLE: "/orderhistory/flexibledemand",
  ORDER_FLEXIBLE: "/flexibledemand",
  ORDER_FLEXIBLE_DETAILS: "/flexibledemand/flexibledemandsummary",
  ORDER_INDUSTRIAL_DETAILS: "/order/orderdetails"
};

export const APPROVAL_LIST = "/pending-approvals";
export const ERROR_PAGE = "/errorpage";
export const SETTINGS_PAGE = "/settings";
export const PRODUCTION_PLAN_PAGE = "/productionplan";
export const OVERVIEW_PAGE = "/overview";
export const STATIC_COST_PAGE = "/staticcosts";
export const CONFIRMATION_PAGE = "/confirmation";
export const PROFILE_PAGE = "/profile";
export const USERS_PAGE = "/users";
export const COMPANY = "/companies";
export const INVOICE_PAGE = "/invoices";
export const HOME_ROUTE = "/";
export const FORECAST = "/forecast";

export const ORDER_TYPE = {
  INDUSTRY_OFFTAKE: "INDUSTRY_OFFTAKE",
  TRAILER_BAY: "TRAILER_BAY"
};

export const MENU_NAME = {
  ORDER_HYDROGEN: "Order Hydrogen",
  ORDER_HISTORY: "Order History",
  ORDER_APPROVAL: "Order Approval",
  INVOICES: "Invoices",
  STATIC_COST: "Static Costs",
  DA_FORECAST: " DA Forecast",
  PRODUCTION_PLAN: " Production Plan",
  OVERVIEW:'Surveillance Overview',
  MANAGE_USER: "Manage Users",
  MANAGE_COMPANIES: "Manage Companies",
  PROFILE: " My Profile",
  SETTINGS: "Settings",
  LOGOUT: "Log out"
};
