import { SET_LOADING, SET_LOADING_FALSE } from "../constants";

export const initialState = {
    loadingFlag: false
}
export const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loadingFlag: action.payload,
            };
        case SET_LOADING_FALSE:
            return {
                ...state,
                loadingFlag: false,
            };
        default:
            return state;
    }
}