import Box from "@mui/material/Box";
import {
    formatValueWithUnit
} from "../../utils/utils";
import Arrow from "../../assets/Icons/dateArrow.svg";
import {convertDateFromUTCToCET} from "../../utils/date-utils";
import {
    ORDER_COLUMN_NAME,
    createdAt,
    startandendtime,
    sum,
    sumP1Price,
    sumP1Total,
    sumP2Price,
    sumP2Total
} from "./RowOrderDetailsConstant";
import {UNITS} from "../../utils/Constants";

export const columns = [
    {
        field: createdAt,
        headerName: ORDER_COLUMN_NAME.ORDER_DATE,
        minWidth: 140,
        type: "dateTime",
        valueGetter: (params) => new Date(params.row.createdAt),
        cellClassname: "order-column-cell",
        headerClassName: "super-app-theme--header",
        renderCell: ({row: {createdAt}}) => {
            return (
                <Box>
                    {convertDateFromUTCToCET(createdAt)}
                </Box>
            );
        }
    },
    {
        field: startandendtime,
        headerName: ORDER_COLUMN_NAME.START_END_TIME,
        minWidth: 270,
        id: 1,
        sortable: false,
        headerClassName: "super-app-theme--header",
        type: "dateTime",
        renderCell: ({row}) => {
            return (
                <Box>
                    {convertDateFromUTCToCET(row.startOffTakeTime)}
                    <img src={Arrow} alt="arrow"/>{convertDateFromUTCToCET(row.endOffTakeTime)}
                </Box>
            );
        },
    },
    {
        field: sum,
        headerName: ORDER_COLUMN_NAME.SUM,
        minWidth: 180,
        id: 3,
        sortable: false,
        type:'number',
        headerClassName: "super-app-theme--header",
        renderCell: ({row: {totalMassDelivered, hydrogenDemand}}) => {
            return (
                <Box>

                    {formatValueWithUnit(`${totalMassDelivered}/${hydrogenDemand}`, UNITS.KG)}
                </Box>
            );
        },
    },
    {
        field: sumP1Total,
        headerName: ORDER_COLUMN_NAME.SUMP1,
        minWidth: 240,
        sortable: false,
        filterable: false,
        hideable: false,
        type:'number',
        headerClassName: "super-app-theme--header",
        renderCell: ({row: {totalP1HydrogenMass}}) => {
            return (
                <Box>
                    {formatValueWithUnit(totalP1HydrogenMass, UNITS.KG)}
                </Box>
            );
        },
    },
    {
        field: sumP2Total,
        headerName: ORDER_COLUMN_NAME.SUMP2,
        minWidth: 200,
        sortable: false,
        filterable: false,
        hideable: false,
        type:'number',
        headerClassName: "super-app-theme--header",
        renderCell: ({row: {totalP2HydrogenMass}}) => {
            return (
                <Box>
                    {formatValueWithUnit(totalP2HydrogenMass, UNITS.KG)}
                </Box>
            );
        },
    },
    {
        field: sumP1Price,
        headerName: ORDER_COLUMN_NAME.SUMP1PRICE,
        sortable: false,
        filterable: false,
        hideable: false,
        headerClassName: "super-app-theme--header",
        minWidth: 235,
        type:'number',
        renderCell: ({row: {totalP1HydrogenPrice}}) => {
            return (
                <Box>
                    {totalP1HydrogenPrice}
                </Box>
            );
        }
    },
    {
        field: sumP2Price,
        headerName: ORDER_COLUMN_NAME.SUMP2PRICE,
        sortable: false,
        filterable: false,
        hideable: false,
        headerClassName: "super-app-theme--header",
        minWidth: 145,
        type:'number',
        renderCell: ({row: {totalP2HydrogenPrice}}) => {
            return (
                <Box>
                    {totalP2HydrogenPrice}
                </Box>
            );
        }
    }
];