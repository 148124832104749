import Box from "@mui/material/Box";
import {formatValueWithUnit} from "../../utils/utils";
import Arrow from "../../assets/Icons/dateArrow.svg";
import { convertDateFromUTCToCET } from "../../utils/date-utils";
import {
  P1Price,
  P1Total,
  P2Price,
  P2Total,
  SUPPLY_LINE_COLUMN_NAME,
  dateTime,
  massDelivered,
  supplyId
} from "./RowOrderDetailsConstant";
import {UNITS} from "../../utils/Constants";
export const supplyOrderDetailsColumn = [
  {
    field: supplyId,
    headerName: SUPPLY_LINE_COLUMN_NAME.SUPPLY_ID,
    minWidth: 140,
    align: "center",
    sortable: false,
    filterable: false,
    hideable: false,
    cellClassname: "order-column-cell",
    headerClassName: "super-app-theme--header",
    renderCell: ({ row: { index } }) => {
      return (
          <Box>
          {index}
        </Box>
      );
    }
  },
  {
    field: dateTime,
    headerName: SUPPLY_LINE_COLUMN_NAME.DATE_TIME,
    minWidth: 270,
    id: 2,
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    headerClassName: "super-app-theme--header",
    type: "dateTime",
    renderCell: ({ row: { startDateTime, endDateTime } }) => {
      return (
          <Box>
          {convertDateFromUTCToCET(startDateTime)}
          <img src={Arrow} alt="arrow" />
          {convertDateFromUTCToCET(endDateTime)}
        </Box>
      );
    }
  },
  {
    field: massDelivered,
    headerName: SUPPLY_LINE_COLUMN_NAME.MASS_DELIVERED,
    minWidth: 180,
    id: 3,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    type:"number",
    headerClassName: "super-app-theme--header",
    renderCell: ({ row: { massDelivered } }) => {
      return (
          <Box>
            {formatValueWithUnit(massDelivered, UNITS.KG)}
          </Box>
      );
    }
  },
  {
    field: P1Total,
    headerName: SUPPLY_LINE_COLUMN_NAME.P1_TOTAL,
    minWidth: 240,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    type:"number",
    headerClassName: "super-app-theme--header",
    renderCell: ({ row: { p1HydrogenMass } }) => {
      return (
          <Box>
            {formatValueWithUnit(p1HydrogenMass, UNITS.KG)}
          </Box>
      );
    }
  },
  {
    field: P2Total,
    headerName: SUPPLY_LINE_COLUMN_NAME.P2_TOTAL,
    minWidth: 200,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    type:"number",
    headerClassName: "super-app-theme--header",
    renderCell: ({ row: { p2HydrogenMass } }) => {
      return (
          <Box>
            {formatValueWithUnit(p2HydrogenMass, UNITS.KG)}
          </Box>
      );
    }
  },
  {
    field: P1Price,
    headerName: SUPPLY_LINE_COLUMN_NAME.P1_PRICE,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    headerClassName: "super-app-theme--header",
    minWidth: 235,
    type:"number",
    renderCell: ({ row: { p1HydrogenPrice } }) => {
      return (
          <Box>
            {p1HydrogenPrice}
          </Box>
      );
    }
  },
  {
    field: P2Price,
    headerName: SUPPLY_LINE_COLUMN_NAME.P2_PRICE,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    headerClassName: "super-app-theme--header",
    minWidth: 145,
    type:"number",
    renderCell: ({ row: { p2HydrogenPrice } }) => {
      return (
          <Box>
            {p2HydrogenPrice}
          </Box>
      );
    }
  }
];