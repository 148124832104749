import { useEffect, useCallback, useState } from "react";
import {
  useIsAuthenticated,
  useMsal,
  AuthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import PropTypes from 'prop-types';
import { UserProvider } from "./UserProvider";
import { loginRequest } from "../../authConfig";
import { API } from "../../utils/API";
import { HOME_ROUTE } from "../../utils/RoutesConstants";

export const AuthorizedPage = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  const { instance: msalInstance, inProgress } = useMsal();
  const [ isAuthorized, setIsAuthorized ] = useState(false);

  const signIn = useCallback(async () => {
    try {
      if (inProgress === InteractionStatus.None) {
        const res = await msalInstance.acquireTokenSilent(loginRequest);

        API.setToken(res.accessToken);
        setIsAuthorized(true);
      }
    } catch (error) {
      msalInstance.loginRedirect({
        scopes: loginRequest.scopes,
      });
    }
  }, [msalInstance, inProgress]);

  const signOut = useCallback(async () => {
    await msalInstance.logoutRedirect({
      postLogoutRedirectUri: HOME_ROUTE,
    });
  }, [msalInstance]);

  useEffect(() => {
    const token = API.getToken();

    if (token) {
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        signIn();
      }
    } else if (inProgress === InteractionStatus.None) {
        signIn();
      }
  }, [isAuthenticated, inProgress, msalInstance, signIn]);

  return (
    <>
      {isAuthorized && <AuthenticatedTemplate>
          <UserProvider signIn={signIn} signOut={signOut}>
            {children}
          </UserProvider>
        </AuthenticatedTemplate>
      }
    </>
  );
};

AuthorizedPage.propTypes = {
  children:PropTypes.any,
};