import React, { useRef, useState, useEffect } from "react";
import stroke from "../../assets/Icons/Stroke.svg";
import { noOp } from "../../utils/utils";
import PropTypes from 'prop-types';
import styles from "./selectinput.module.css";
import { Box } from "@mui/material";

const SelectInput = ({
  name,
  value,
  inputProps,
  setValue,
  clearErrors,
  options = [],
  dataTestId = "",
  inputDataTestId = "",
  optionDataTestId = "",
  optionInputDataTestId = "",
  errorMsg = "",
  extraWrapperClass = "",
  onChangeCallBackFn = noOp
}) => {
  const wrapperRef = useRef(null);
  const [showOption, setShowOption] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOption(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const setSelectedOption = (value) => {
    if(clearErrors) {
      clearErrors(name);
    }
    if(setValue) {
      setValue(name, value);
    }
    if(onChangeCallBackFn) {
      onChangeCallBackFn()
    }
    setShowOption(false);
  };

  return (
      <div ref={wrapperRef} className={`${inputProps?.disabled ? styles.input_disabled : styles.main_wrapper}`}>
        <div
          className={`${extraWrapperClass} ${
            errorMsg && `${styles.select__input_error}`
          }`}
        >
          <Box
            className={`${styles.select__input} ${
              errorMsg ? `${styles.select__input_error}` : ``
            }`}
            onClick={() => setShowOption(!showOption)}
            data-testid={dataTestId}
          >
            <div className={styles.select__input__textAndInput}>
              {value && (
                <small
                  className={`${styles.select__input__small} ${
                    errorMsg ? `${styles.select__input__smallError}` : ``
                  }`}
                >
                  {inputProps?.placeholder ?? ""}
                </small>
              )}
              <input
                type="text"
                readOnly
                className={styles.select__input_value}
                data-testid={inputDataTestId}
                {...inputProps}
              />
            </div>
            <div className={styles.select__input__strokeImg}>
              <img
                src={stroke}
                alt="stroke"
                className={styles.select__input__stroke}
              />
            </div>
          </Box>
          {showOption && (
            <div
              className={styles.select__input_option}
              data-testid={optionDataTestId}
            >
              <ul>
                {options.map(({ label, value }) => {
                  return (
                    <Box 
                    onClick={() => setSelectedOption(value)}
                    key={`${name}-${value}`}
                    data-testid={optionInputDataTestId}
                    >
                    <li>
                      {label}
                    </li>
                    </Box>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        {errorMsg && (
          <div className={styles.select__input_value__error}>{errorMsg}</div>
        )}
      </div>
  );
};
SelectInput.propTypes = {
  name:PropTypes.string,
  value:PropTypes.any,
  inputProps:PropTypes.any,
  setValue:PropTypes.any,
  clearErrors:PropTypes.any,
  options:PropTypes.any,
  dataTestId:PropTypes.string,
  inputDataTestId:PropTypes.string,
  optionDataTestId:PropTypes.string,
  optionInputDataTestId:PropTypes.string,
  errorMsg:PropTypes.string,
  extraWrapperClass:PropTypes.string,
  onChangeCallBackFn:PropTypes.func,
};
export default SelectInput;
