import { SET_APPROVAL_STATUS, CLEAR_APPROVAL_STATUS } from "../constants";

export const initialState = {
    status: null
}
export const approvalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APPROVAL_STATUS:
            return {
                ...state,
                status: action.payload,
            };
        case CLEAR_APPROVAL_STATUS:
            return {
                ...state,
                status: null,
            };
        default:
            return state;
    }
}