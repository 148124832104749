import React, { useState } from "react";
import styles from "./selectInputWithObject.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import PropTypes from 'prop-types';
const SelectInputWithObject = ({
  value,
  options,
  rawOptions,
  inputProps,
  optionDataTestId = "",
  onChangeCallBackFn,
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    onChangeCallBackFn(
      rawOptions?.filter((itm) => itm.id === e.target.value)[0]?.id
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <FormControl
        className={styles.field}
        sx={{ m: 1, width: "100%", margin: "0" }}
      >
        <InputLabel
          className={styles.select__input_value}
        >
          {inputProps.placeholder}
        </InputLabel>
        <Select
          style={{ height: 70 }}
          IconComponent={ExpandMoreIcon}
          sx={{
            "& .MuiSvgIcon-root": { color: "#718290" },
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": {
              borderBottom: `2px solid var(--grayColor)`,
              borderRadius: 0,
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderBottom: `2px solid var(--grayColor)`,
              borderRadius: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderBottom: `2px solid var(--grayColor)`,
                borderRadius: 0,
              },
            "MuiInputBase-input-MuiOutlinedInput-input": {
              fontWeight: 700,
              fontFamily: "sans-serif",
              color: "#718191",
            },
          }}
          {...inputProps}
          value={selectedValue || value}
          onChange={(e) => handleChange(e)}
        >
          {options.map((item) => (
            <MenuItem
              data-testid={optionDataTestId}
              key={`select-${item.value}`}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};
SelectInputWithObject.propTypes = {
  value:PropTypes.string,
  options:PropTypes.any,
  rawOptions:PropTypes.any,
  inputProps:PropTypes.any,
  optionDataTestId:PropTypes.string,
  onChangeCallBackFn:PropTypes.func,
};
export default SelectInputWithObject;
