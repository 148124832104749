import * as signalR from "@microsoft/signalr";
import axios from "axios";
import {
  fetchDataRequest,
  fetchDataSuccess,
  fetchOrderDetailsSuccess,
  fetchDataError,
  fetchUserDetailsSuccess,
  setNetworkErrorMessage
} from ".";
import { API } from "../../utils/API";
import { errorHandler } from "../../utils/utils";
import { ACTIONS_NAME } from "../constants";
import { store } from "../store";
import { NOTIFICATION_STATUS } from "../../utils/Constants";
import dayjs from "dayjs";

export const fetchStaticCosts = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(API.STATIC_COST_BASE_URL)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.STATIC_COST));
      })
      .catch((error) => {
        dispatch(fetchDataError(error.message));
      });
  };
};

export const fetchUser = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(API.USER_BASE_URL)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.USER));
      })
      .catch((error) => {
        dispatch(fetchDataError(error.message));
      });
  };
};

export const fetchInvoiceList = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(API.INVOICE_BASE_URL)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.INVOICES));
      })
      .catch((error) => {
        dispatch(fetchDataError(error.message));
      });
  };
};
export const fetchRoles = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(API.ROLES_BASE_URL)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.ROLES));
      })
      .catch((error) => {
        dispatch(fetchDataError(error.message));
      });
  };
};

export const fetchTypes = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(API.STATIC_COST_TYPE)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.TYPES));
      })
      .catch((error) => {
        dispatch(fetchDataError(error.message));
      });
  };
};
export const fetchCompanies = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(API.COMPANIES_BASE_URL)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.COMPANIES));
      })
      .catch((error) => {
        dispatch(fetchDataError(error.message));
      });
  };
};

export const fetchOrderDetails = (orderId, index) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(`${API.ORDERS_BASE_URL}/${orderId}`)
      .then((response) => {
        return dispatch(fetchOrderDetailsSuccess(response?.data, index));
      })
      .catch((error) => {
        dispatch(fetchDataError(error.message));
      });
  };
};

export const fetchUserDetails = (userId) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(`${API.USER_BASE_URL}/${userId}`)
      .then((response) => {
        return dispatch(fetchUserDetailsSuccess(response?.data));
      })
      .catch((error) => {
        dispatch(fetchDataError(error.message));
      });
  };
};

export const fetchInvoiceDetails = (invoiceId) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(`${API.INVOICE_BASE_URL}/${invoiceId}`)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.INVOICE_DETAILS));
      })
      .catch((error) => {
        dispatch(fetchDataError(error.message));
      });
  };
};

export const fetchMaintenance = (navigate, signIn, signOut) => {
  return async(dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(API.MAINTENANCE)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.MAINTENANCE));
      })
      .catch((error) => {
        errorHandler(error, navigate, dispatch, signIn, signOut, "maintenance");
      });
  };
};

export const fetchOrdersWaitingForApproval = (navigate, signIn, signOut) => {
  return async(dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(`${API.ORDERS_BASE_URL}?status=WAITING_FOR_APPROVAL`)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.ORDERS_WAITING_FOR_APPROVAL));
      })
      .catch((error) => {
        errorHandler(error, navigate, dispatch, signIn, signOut, "approval");
      });
  };
};

export const getNotifications = (navigate, signIn, signOut,notificationsCount) => {
  return async(dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(`${API.NOTIFICATIONS}?pagesize=${notificationsCount}&IsRead=false`)
      .then((response) => {
        const newData = response?.data?.map((obj)=>({
          ...obj, 
          statusEnum: NOTIFICATION_STATUS[obj.status]
      }));
        newData.sort((a,b)=>a.statusEnum - b.statusEnum);
        return dispatch(fetchDataSuccess(newData,ACTIONS_NAME.NOTIFICATIONS));
      })
      .catch((error) => {
        errorHandler(error, navigate, dispatch, signIn, signOut, "notifications");
      });
  };
};

export const getNotificationsCount = (navigate, signIn, signOut) => {
  return async(dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(`${API.NOTIFICATIONS}/count`)
      .then((response) => {
        return dispatch(fetchDataSuccess(response?.data,ACTIONS_NAME.NOTIFICATIONS_COUNT));
      })
      .catch((error) => {
        errorHandler(error, navigate, dispatch, signIn, signOut, "notifications");
      });
  };
};

export const getNotificationsFromSignalR = async(dispatch) => {
  try {
    const url = process.env.REACT_APP_SIGNALR_CONNECTION_URL;
    const response = await axios.post(`${url}/negotiate`, null, {
      headers: {
          "Authorization":API.getToken()
      }
  })
  const options = {
    accessTokenFactory: () => response.data?.accessToken
  }
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(response?.data?.url,options)
      .build();
    connection.on('newMessage', (messageReceived) => {
      const notificationsList = store.getState().orderReducer.notifications;
      if (notificationsList) {
        const updatedNotificationsList = [JSON.parse(messageReceived.Text), ...notificationsList];
        const countObject = {
          count: updatedNotificationsList?.length
        }
        dispatch(fetchDataSuccess(updatedNotificationsList, ACTIONS_NAME.NOTIFICATIONS));
        dispatch(fetchDataSuccess(countObject, ACTIONS_NAME.NOTIFICATIONS_COUNT));
      }
    });

    connection.start();
  } catch (error) {
    dispatch(
      setNetworkErrorMessage({
        errorMessage: error.message
      })
    );
  }
}

export const fetchPriceForecast = (navigate, signIn, signOut) => {
  return async(dispatch) => {
    dispatch(fetchDataRequest());
    return API.get(API.FORECAST)
      .then((response) => {
        const newData = response.data.map((obj,index)=>({
          ...obj, 
          id:index
      }));    
        const sortedData = newData?.sort((a, b) => {
          return dayjs(a.date).diff(dayjs(b.date));
      });
        return dispatch(fetchDataSuccess(sortedData,ACTIONS_NAME.FORECAST));
      })
      .catch((error) => {
        errorHandler(error, navigate, dispatch, signIn, signOut, "forecast");
      });
  };
};