import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import PropTypes from 'prop-types';
import ConfirmationModal from "../confirmation-modal/ConfirmationModal";
import { CONFIRM_DIALOG_PROVIDER } from "../ComponentsConstants";
const confirmDialog = createContext();
export function ConfirmDialogProvider({ children }) {
  const [state, setState] = useState({ isOpen: false });
  const fn = useRef();
  const confirm = useCallback(() => {
    return new Promise((resolve) => {
      setState({ isOpen: true });
      fn.current = (choice) => {
        resolve(choice);
        setState({ isOpen: false });
      };
    });
  }, []);
  return (
    <confirmDialog.Provider value={confirm}>
      {children}
      <ConfirmationModal
        heading={CONFIRM_DIALOG_PROVIDER.HEADER}
        open={state.isOpen}
        handleClose={() => fn.current(false)}
        handleAction={() => fn.current(true)}
        textToShow={CONFIRM_DIALOG_PROVIDER.MESSAGE_CONTENT}
        firstBtnText={CONFIRM_DIALOG_PROVIDER.BTN_CANCEL}
        secondBtnText={CONFIRM_DIALOG_PROVIDER.BTN_OK}
      />
    </confirmDialog.Provider>
  );
}
ConfirmDialogProvider.propTypes = {
  children: PropTypes.any,
};
export default function useConfirm() {
  return useContext(confirmDialog);
}
