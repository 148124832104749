import React from "react";
import Graphic from "../../assets/Icons/Graphic.svg";
import { Box } from "@mui/system";
import { useIsAuthenticated } from "@azure/msal-react";
import styles from "./home.module.css";
const Home = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <div className={styles.homeContainer}>
        <Box
          sx={{ fontSize: 50, color: "var(--white)", fontFamily: "Source Sans Pro" }}
        >
          Water <br /> formation? <br />
        </Box>
        <br />
        <br />
        <Box
          sx={{ fontSize: 30, color: "var(--white)", fontFamily: "Source Sans Pro" }}
        >
          The name hydrogen comes <br />
          from Greek words: <br />
          Hydro meaning 'Water', and <br />
          gene meaning 'forming'.
        </Box>
      </div>
      {isAuthenticated && <Box
        sx={{
          height: 150,
          display: "flex",
          marginTop: 70,
          justifyContent: "flex-end",
        }}
      >
        <img src={Graphic} alt="settings" />
      </Box>}
    </>
  );
};

export default Home;
