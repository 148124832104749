import React from "react";
import SelectInput from "../select-input/SelectInput";
import PropTypes from 'prop-types';
const Timepicker = ({
  name,
  hour,
  inputProps,
  setValue,
  clearErrors,
  errorMsg,
  extraWrapperClass = "",
}) => {
  const hoursArray = Array.from(Array(24).keys());

  const mapHours = () => {
    return hoursArray.map((hour) => {
      const hourFormat = ('0' + hour).slice(-2);
      const hourValue = `${hourFormat}:00`;
      return {
        label: hourValue,
        value: hourValue,
      };
    });
  }

  return (
      <SelectInput
        name={name}
        value={hour}
        options={mapHours()}
        inputProps={inputProps}
        setValue={setValue}
        clearErrors={clearErrors}
        dataTestId="showHourdDropDown"
        inputDataTestId="timeIp"
        optionDataTestId="hoursDropDown"
        optionInputDataTestId="hours"
        extraWrapperClass={extraWrapperClass}
        errorMsg={errorMsg}
      />
  );
};
Timepicker.propTypes = {
  name:PropTypes.string,
  hour:PropTypes.any,
  inputProps:PropTypes.any,
  setValue:PropTypes.any,
  clearErrors:PropTypes.any,
  errorMsg:PropTypes.string,
  extraWrapperClass:PropTypes.string,
};
export default Timepicker;
