import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./errorpage.module.css";
import { UserContext } from "../../pages/AuthorizedPage/UserProvider";
import { useIsAuthenticated } from "@azure/msal-react";
import { Box, Modal, Typography } from "@mui/material";
import crossIcon from "../../assets/Icons/close.svg";
import {
  clearEditFlexibleDemand,
  clearFlexibleDemand,
  clearOrderDetails,
} from "../../store/actions";
import { HOME_ROUTE, ORDER_PAGE } from "../../utils/RoutesConstants"
import { ErrorMessage } from "../../utils/ErrorConstants";
import { EDIT_WARNING_MODAL } from "../../pages/Order/constants";
import { ERROR_PAGE } from "../ComponentsConstants";
import PropTypes from 'prop-types';

const ErrorPage = ({ message }) => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { signIn,signOut} = useContext(UserContext);
  const dispatch = useDispatch();
  const networkErrorMessage = useSelector(
    (state) => state.orderReducer?.networkErrorMessage?.errorMessage
  );
  const networkErrorCode = useSelector(
    (state) => state.orderReducer?.networkErrorMessage?.errorCode
  );
  useEffect(() => {
    if (networkErrorMessage === undefined) {
      navigate(HOME_ROUTE);
    }
  }, [navigate, networkErrorMessage]);
  const editWarningModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    height: 402,
    borderRadius: 4,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    fontFamily: "Source Sans Pro",
  };
  const editModalTextStyle = {
    color: "customColor.normalTextColor",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
  };
  const backToOrderHistory = () => {
    dispatch(clearFlexibleDemand());
    dispatch(clearEditFlexibleDemand());
    dispatch(clearOrderDetails());
    navigate(ORDER_PAGE.ORDER_HISTORY);
  };
  const renderEditWarningModal = () => {
    return (
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={editWarningModalStyle}>
            <Box
              sx={{
                width: 400,
                height: 36,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "28px",
                  fontWeight: 900,
                  lineHeight: "36px",
                  letterSpacing: "0.20000000298023224px",
                  color: "customColor.normalTextColor",
                }}
              >
               {ERROR_PAGE.EDIT_MESSAGE}
              </Typography>
              <Box onClick={backToOrderHistory}>
              <img
                src={crossIcon}
                alt="crossIcon"
                style={{ cursor: "pointer" }}
              />
              </Box>
            </Box>
            <Box
              sx={{
                width: 400,
                height: 192,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-around",
                flexDirection: "column",
                marginTop: "30px",
              }}
            >
              <Box
                sx={{
                  ...editModalTextStyle,
                }}
              >
                {EDIT_WARNING_MODAL}
              </Box>
              <Box>
                <Typography
                  sx={{
                    ...editModalTextStyle,

                    fontWeight: 700,
                  }}
                >
                  Sondre Torp
                </Typography>
                <Typography
                  sx={{
                    ...editModalTextStyle,
                    textDecoration: "underline",
                  }}
                >
                  +440123456789
                </Typography>
                <Typography
                  sx={{
                    ...editModalTextStyle,
                    textDecoration: "underline",
                  }}
                >
                  st@everfuel.com
                </Typography>
              </Box>
            </Box>
            <Box>
              <button
                className={`${styles.errorPage__login} ${styles.editModalBtn}`}
                onClick={backToOrderHistory}
              >
                {ERROR_PAGE.OK_TEXT}
              </button>
            </Box>
          </Box>
        </Modal>
    );
  };
  const handleClick = () =>{
    if(networkErrorMessage === ErrorMessage.PROFILE_NOT_FOUND_ERROR_MESSAGE){
      signOut();
    }
    else{
      navigate(-1);
    }
  };

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorPage__container}>
        {networkErrorCode !== "HYSD1007" && (
          <>
            <h1 className={styles.errorPage__heading}>An error has occurred</h1>
            <h3 className={styles.errorPage__subheading}>
              {message || networkErrorMessage}
            </h3>
            {isAuthenticated ? (
              <button
                className={styles.errorPage__login}
                onClick={handleClick}
                data-testid="toBack"
              >
                {networkErrorMessage ===  ErrorMessage.PROFILE_NOT_FOUND_ERROR_MESSAGE  ? ERROR_PAGE.BTN_CLOSE_TXT : ERROR_PAGE.BTN_TRY_AGAIN_TXT}
              </button>
            ) : (
              <button
                className={styles.errorPage__login}
                onClick={signIn}
                data-testid="signIn"
              >
                LogInn
              </button>
            )}
          </>
        )}

        {networkErrorCode === "HYSD1007" && renderEditWarningModal()}
      </div>
    </div>
  );
};
ErrorPage.propTypes = {
  message : PropTypes.string,
};

export default ErrorPage;
