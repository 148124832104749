import { createContext, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';

export const UserContext = createContext({
  signIn: () => {},
  signOut: () => {},
});

export const UserProvider = ({ children, signIn, signOut }) => {
  useEffect(() => {}, []);

  const providerValue = useMemo(() => ({ signIn, signOut }), [signIn, signOut]);

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};
UserProvider.propTypes = {
  children:PropTypes.any,
  signIn:PropTypes.func,
  signOut:PropTypes.func
};