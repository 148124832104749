import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(timezone);

export const factoryTimezone = "Europe/Berlin";
export const convertDateStrToDateObj = (dateStr) => {
  return dayjs(dateStr);
};

export const dateIsSame = (date1, date2) => date1.isSame(date2);

export const dateIsAfter = (date1, date2) => date1.isAfter(date2);

export const dateIsSameOrAfter = (date1, date2) => date1.isSameOrAfter(date2);

export const dateToUTC = (date, format = "YYYY-MM-DDTHH:mm:ss") => {
  return format === null ? dayjs.utc(date) : dayjs.utc(date).format(format);
};

export const dateStringToUTCString = (dateStr, format = 'YYYY-MM-DDTHH:mm:ss') => {
  return dayjs(dateStr).utc().format(format);
};
export const dateStringToUTCObject = (date) => {
  return dayjs.utc(date);
};
export const dateTimeDifference = (startDate, endDate, unit = "hours") =>
  endDate.diff(startDate, unit);

export const createCetDateObject = (date, hour = "") => {
  return hour
    ? dayjs.tz(`${date} ${hour}`?.trim(), factoryTimezone)
    : dayjs(date);
};

export const getStartOfTheDayFromUTCInCetDateObject=(date)=> {
  return dayjs.utc(date).tz(factoryTimezone).hour(0).minute(0).second(0).millisecond(0);
};

export const getTodayBeginningInCET=()=> {
  return dayjs.utc().tz(factoryTimezone).hour(0).minute(0).second(0).millisecond(0);
};

export const getEndOfTheDayFromUTCInCetDateObject=(date)=> {
  return dayjs.utc(date).tz(factoryTimezone).hour(0).minute(0).second(0).add(1,'day');
};

export const getStartOfTheDayFromMillsToCetDateObject=(date)=> {
  return dayjs.tz(date,factoryTimezone).hour(0).minute(0).second(0).millisecond(0);
};

export const convertDateFromUTCToCETObject = (utcDateString) => {
  return dayjs.utc(utcDateString).tz(factoryTimezone);
};

export const convertDateFromUTCToCET = (
  utcDateString,
  format = "DD-MM-YYYY HH:mm"
) => {
  return dayjs.utc(utcDateString).tz(factoryTimezone).format(format);
};

export const getHourDifference = (
  startDate,
  endDate,
  startHour = "",
  endHour = ""
) => {
  const startDateTime = createCetDateObject(startDate, startHour);
  const endDateTime = createCetDateObject(endDate, endHour);
  return dateTimeDifference(startDateTime, endDateTime);
};

export const formatDateNoUTC = (date, format = "DD-MM-YYYY HH:mm") => {
  return dayjs(date).format(format);
};

export const calculateDuration = (startDateTime, endDateTime) => {
  const timeDuration = dayjs.duration(endDateTime.diff(startDateTime));
  const days = Math.floor(timeDuration.asDays());
  const hours = timeDuration.get("hours");
  return `${days} ${days > 1 ? "days" : "day"}, ${hours} ${
    hours > 1 ? "hours" : "hour"
  }`;
};

export const calculateDurationForNotifications = (startDateTime, endDateTime) => {
  const timeDuration = dayjs.duration(endDateTime.diff(startDateTime));  
  const days = timeDuration.get('days');
  const hours = timeDuration.get("hours");
  const minute = timeDuration.get('minutes');
  if(days <= 0 && hours <= 0){
    return `${minute}m`;
  }else{
    return `${days}d`;
  }
};

export const calculateDurationInHour = (startDateTime, endDateTime) => {
  const timeDuration = dayjs.duration(endDateTime.diff(startDateTime));
  return timeDuration.asHours();
};

export const addToDate = (
  value,
  unit,
  date = new Date(),
  format = "YYYY-MM-DD"
) => dayjs(date).add(value, unit).format(format);

export const getMinimumStartDate = () => {
  const cetDate = dayjs(new Date()).tz(factoryTimezone);
  const hour = dayjs(cetDate).hour();
  if (hour < 10) {
    return addToDate(1, "days");
  } else {
    return addToDate(2, "days");
  }
};
export const isCurrentTimezoneIsCET = () => {
  const localDate = dayjs(new Date());
  const cetDate = localDate.tz(factoryTimezone);
  return (
    cetDate.format("DD/MM/YYYY HH:mm") === localDate.format("DD/MM/YYYY HH:mm")
  );
};

export const getMaxOneYear = () => {
  const minDate = new Date();
  const maxDateRange = new Date(
    minDate.getFullYear() + 1,
    minDate.getMonth(),
    minDate.getDate()
  );
  return convertDateFromUTCToCET(maxDateRange, "YYYY-MM-DD");
};

export const getMaxDays = (startDate, days) => {
  if (startDate) {
    const maxDays = new Date(
      new Date(startDate).getTime() + days * 24 * 60 * 60 * 1000
    );
    return convertDateStrToDateObj(maxDays);
  }
};

export const isBetweenDates = (startDate, endDate) => {
  const today = dayjs().hour(0).minute(0).second(0).millisecond(0)
  return (
    new Date(convertDateFromUTCToCET(startDate, "YYYY-MM-DD")) <= today &&
    today <= new Date(convertDateFromUTCToCET(endDate, "YYYY-MM-DD"))
  );
};

export const getUTCMinuteDifferenceForBRP = (date) => {
  const brpTime = dateStringToUTCObject(date).valueOf();
  const currentUTCTime = dayjs().utc().valueOf();
  const timeDuration = currentUTCTime - brpTime;
  return dayjs.duration(timeDuration).asMinutes();
}
