import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import styles from "./CustomNoRowsOverlay.module.css";

const CustomNoRowsOverlay = (props) => {
  const { message } = props;
  return (
    <Box className={styles.message}>
      {message}
    </Box>
  );
};

CustomNoRowsOverlay.propTypes = {
  message: PropTypes.string,
};

export default CustomNoRowsOverlay;
