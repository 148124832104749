import { Fragment, useContext, useState } from 'react';
import { Transition } from '@headlessui/react';
import crossIcon from "../../assets/Icons/close.svg";
import { useDispatch, useSelector } from 'react-redux';
import RowOrderDetails from "../RowOrderDetails/RowOrderDetails";
import { calculateDurationForNotifications, convertDateFromUTCToCETObject } from '../../utils/date-utils';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { ERROR_PAGE } from '../../utils/RoutesConstants';
import minimal from "../../assets/Icons/Minimal.svg";
import maintenance from "../../assets/Icons/Maintenance.svg";
import { API } from '../../utils/API';
import { ErrorCodes } from '../../utils/ErrorConstants';
import { fetchDataSuccess, setNetworkErrorMessage } from '../../store/actions';
import { errorHandler, notificationNavigation } from '../../utils/utils';
import { UserContext } from '../AuthorizedPage/UserProvider';
import { fetchOrderDetails, getNotifications, getNotificationsCount } from '../../store/actions/fetchData';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE, TRANS_KEYS } from '../../utils/Constants';
import Inbox from '../Inbox/Inbox';
import { ACTIONS_NAME } from '../../store/constants';

const Notification = ({ notificationOpen, setNotificationOpen }) => {

    const { t } = useTranslation(TRANS_KEYS.translation, {
        keyPrefix: TRANS_KEYS.NOTIFICATION
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { signIn, signOut } = useContext(UserContext);
    const [showInbox, setShowInbox] = useState(false);
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const { notifications, notificationsCount } = useSelector(
        (state) => state?.orderReducer
    );
    const notificationDataItemLimit = notifications?.slice(0, 5);

    const handleNotificationClick = async (notificationObject) => {
        try {
            notificationObject.type !== NOTIFICATION_TYPE.ORDER_CREATED && setNotificationOpen(!notificationOpen);
            const url = `${API.NOTIFICATIONS}/${notificationObject?.id}/read`;
            notificationObject.statusEnum !== NOTIFICATION_STATUS.SEEN && await API.patch(url, {});
            dispatch(getNotifications(navigate, signIn, signOut,notificationsCount?.count));
            dispatch(fetchDataSuccess(notificationObject,ACTIONS_NAME.NOTIFICATION_OBJECT));
            if(notificationObject?.type === NOTIFICATION_TYPE.ORDER_CREATED){
                dispatch(fetchOrderDetails(notificationObject?.metadata?.resourceId,1));
                setShowOrderDetails(!showOrderDetails);
            }else{
                dispatch(getNotificationsCount(navigate, signIn, signOut));
                await notificationNavigation(navigate, notificationObject);
            }
        } catch (error) {
            if (error.errorCode === ErrorCodes.BadRequest) {
                dispatch(setNetworkErrorMessage({ errorMessage: error.message }));
            } else {
                errorHandler(error, navigate, dispatch, signIn, signOut, "notifications");
            }
            navigate(ERROR_PAGE);
        }
    }

    return (
        <>
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-16 mr-40"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    <Transition
                        show={notificationOpen}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className='flex pt-2.5 justify-center items-center'>
                                <p className="text-base font-black h-6 w-10/12">{t(`INBOX`)}</p>
                                <div className="float-right">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 border-none"
                                        onClick={() => {
                                            setNotificationOpen(!notificationOpen)
                                        }}
                                    >
                                        <img
                                            src={crossIcon}
                                            alt="crossIcon"
                                        />
                                    </button>
                                </div>
                            </div>

                            {notifications?.length > 5 && <p aria-hidden="true" onClick={() => setShowInbox(true)} className="cursor-pointer text-base text-blue-700 h-6 w-16 ml-5 underline">{t(`SHOW_ALL`)}</p>}
                            {notificationDataItemLimit?.length > 0 ? notificationDataItemLimit?.map((item) => {
                                return <div className="p-4 border-b" key={item?.id}>
                                    <div className="flex items-start">
                                        <div className="w-12 h-12 mt-2">
                                            <img
                                                src={item?.type.includes('ORDER_') ? minimal : maintenance}
                                                alt="hydrogenIcon"
                                            />
                                        </div>
                                        <div aria-hidden="true" data-testid="notificationMessage" className="ml-3 w-0 flex-1 pt-0.5 cursor-pointer" onClick={() => handleNotificationClick(item)}>
                                            <div className='flex'>
                                                {item?.statusEnum === NOTIFICATION_STATUS.SEEN ? <p className="text-sm w-11/12">{item?.title}</p> : <p className="text-sm font-bold text-gray-900 w-11/12">{item?.title}</p>}
                                                <p className="text-sm font-normal text-xs text-gray-500 w-8">{calculateDurationForNotifications(convertDateFromUTCToCETObject(item?.createdAt), convertDateFromUTCToCETObject(dayjs().toString()))}</p>
                                            </div>
                                            <p className="mt-1 text-sm font-normal text-gray-500 w-3/4">{item?.body}</p>
                                        </div>
                                    </div>
                                </div>
                            }) :
                                <div className='flex justify-center	items-center p-4 text-sm font-normal text-gray-500'>{t(`NO_NOTIFICATION`)}</div>}
                        </div>
                    </Transition>
                </div>
                {showInbox && <Inbox handleNotificationClick={handleNotificationClick} showInbox setShowInbox={setShowInbox} />}
            </div>
            {showOrderDetails && (
                <RowOrderDetails
                    routeFrom={true}
                    setNotificationOpen={setNotificationOpen}
                    setShowOrderDetails={setShowOrderDetails}
                    showOrderDetails={showOrderDetails}
                />
            )}
        </>
    )
}
Notification.propTypes = {
    notificationOpen: PropTypes.bool,
    setNotificationOpen: PropTypes.func,
};
export default Notification;