import { useNavigate } from "react-router-dom";
import React from "react";
import styles from "./errorpage.module.css";
import { BUTTON_NAME } from "../../store/constants";
import {HOME_ROUTE} from "../../utils/RoutesConstants";
import PropTypes from 'prop-types';

const ErrorPageNotFound = ({ message }) => {
  const navigate = useNavigate();

  const handleClick = () =>{
      navigate(HOME_ROUTE);
  };

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorPage__container}>
      
            <h1 className={styles.errorPage__heading}>An error has occurred</h1>
            <h3 className={styles.errorPage__subheading}>
              {message}
            </h3>
              <button
                className={styles.errorPage__login}
                onClick={handleClick}
                data-testid="toBack"
              >
               {BUTTON_NAME}
              </button>
      </div>
    </div>
  );
};
ErrorPageNotFound.propTypes = {
  message : PropTypes.string,
};

export default ErrorPageNotFound;
