import React from "react";

import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Box
      sx={{
        margin: "0 auto",
        "& .Mui-selected": {
          backgroundColor: "#E2EDF8 !important",
          color: "#1E3A4F !important",
          width: "56px",
          height: "56px",
          borderRadius: "8px",
          padding: "16px 12px 16px 12px",
        },
        "& .MuiPagination-ul > li > button": {
          color: "#9EAAB3",
          width: "56px",
          height: "56px",
          borderRadius: "8px",
          padding: "16px 12px 16px 12px",
        },
        "& .MuiPagination-ul > li > button:hover": {
          backgroundColor: "#1E3A4F !important",
          color: "#fff !important",
        },
      }}
    >
      <Pagination
        color="primary"
        page={page + 1}
        count={pageCount}
        shape="rounded"
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Box>
  );
};

export default CustomPagination;
