import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React from "react";
import crossIcon from "../../assets/Icons/close.svg";
import styles from "./confirmationmodal.module.css";
import HeaderTitle from "../../pages/page-Header/HeaderTitle";
import PropTypes from 'prop-types';

const ConfirmationModal = ({
  open,
  handleClose,
  handleAction,
  textToShow,
  firstBtnText,
  secondBtnText,
  error,
  loading,
  heading = "Back to Menu",
  actionButtonIcon
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    height: 292,
    borderRadius: 4,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: 400,
              height: 36,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <HeaderTitle title={heading}/>
            <Box onClick={handleClose}>
            <img
              src={crossIcon}
              alt="crossIcon"
              style={{ cursor: "pointer" }}
            />
            </Box>
          </Box>
          <Typography
            sx={{
              width: 400,
              height: 28,
              marginTop: 4,
              color:'customColor.grayMedium'
            }}
          >
            {textToShow}
          </Typography>
          {error && (
            <Typography
              sx={{
                color: "customColor.redColor",
                fontSize: "13px",
              }}
            >
              {error}
            </Typography>
          )}

          {loading && (
            <Backdrop
              sx={{ color: "var(--white)", borderRadius: 4 }}
              open={loading}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Box
            sx={{
              width: 400,
              height: 56,
              display: "flex",
              alignItems: "center",
              marginTop: 8,
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            {firstBtnText && <button
              className={`${styles.btn} ${styles.backBtn}`}
              onClick={handleClose}
              data-testid="closeModal"
            >
              {firstBtnText}
            </button>}
            <button
              className={`${styles.btn} ${styles.orderBtn}`}
              data-testid="toHomePage"
              onClick={handleAction}
            >
             {actionButtonIcon} {secondBtnText}
            </button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
ConfirmationModal.propTypes = {
  open : PropTypes.bool,
  handleClose:PropTypes.func,
  handleAction:PropTypes.func,
  textToShow:PropTypes.string,
  firstBtnText:PropTypes.string,
  secondBtnText:PropTypes.string,
  error:PropTypes.string,
  loading:PropTypes.bool,
  heading :PropTypes.string,
  actionButtonIcon: PropTypes.any
};
export default ConfirmationModal;
