import Box from "@mui/material/Box";
import commonStyles from '../../common/Common.module.css';
import { NOTIFICATION_STATUS } from "../../utils/Constants";
export const columns = [
  {
    field: 'title',
    headerName: 'Title',
    width: 300,
    maxWidth: 300,
    valueGetter: (params) => params.row.title,
    headerClassName: "super-app-theme--header",
    renderCell: ({ row }) => {
      return (
        <Box className={commonStyles.dataGridStyleFirstColumn} style={{fontWeight: row.statusEnum !== NOTIFICATION_STATUS.SEEN ? '700' : '100' }}>
          {row?.title}
        </Box>
      );
    },
  },
  {
    field: 'body',
    headerName: 'Description',
    minWidth: 900,
    valueGetter: (params) => params.row.body,
    headerClassName: "super-app-theme--header",
    renderCell: ({ row }) => {
      return (
        <Box>
          {row?.body}
        </Box>
      );
    },
  }
];
