import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    // Inputs
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .Mui-focused": {
            color:"red",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            }
          }
        }
      }
    }
  }
});

export default theme;
